import React from "react"
import Footer from "../../../components/footer"
import Header from "../../../components/header"
import SEO from "../../../components/SEO"
import "../../../styles/ebook-infographic.less"
import { ConsultationPopButton } from "../../../components/form-biz"
import { Button } from "../../../components/ui"
const loadText = require("src/utils").loadText
const data = loadText("ebook-infographic")
const TopBanner = () => {
  const { topBanner } = data
  return (
    <div className="topBanner-wrapper">
      <div className="topBanner">
        <div className="topBanner-bg">
          <div className="headerContainer ">
            <Header logo="/logo-blue.svg" />
          </div>

          <div className="topBanner-content">
            <div className="left">
              <div className="title">{topBanner.title}</div>
              <div className="subtitle mt-24">{topBanner.subtitle}</div>
              <div className="description mt-24">{topBanner.description}</div>
            </div>
            <div className="right">
              <picture>
                <source
                  srcSet={`/ebook-infographic/topBanner-pic-m.png 640w `}
                  media="(max-width: 640px)"
                />
                <img src="/ebook-infographic/topBanner-pic.png" alt="ebook" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Opportunity = () => (
  <div className="op">
    <div className="op-bg">
      <div className="op-title">{data.opportunity.title}</div>
      <div className="op-subtitle">{data.opportunity.subtitle}</div>
      <div className="op-content">
        <div className="left">{data.opportunity.left.text}</div>
        <div className="right">
          <img src="/ebook-infographic/up.svg" alt="up" />
          <div className="stats">{data.opportunity.right.stats}</div>
          <div className="description">
            {data.opportunity.right.description}
          </div>
        </div>
      </div>
    </div>
  </div>
)
const Graph = () => (
  <div className="graph">
    <picture>
      <source
        srcSet={`/ebook-infographic/graph-m.png 640w `}
        media="(max-width: 640px)"
      />
      <img src="/ebook-infographic/graph.svg" alt="graph" />
    </picture>
    <div className="btn-row">
      <Button
        width={320}
        className="only-desktop"
        onClick={() => {
          window.open(data.graph.link)
        }}
      >
        {data.graph.btn}
      </Button>
      <Button
        width={496}
        className="only-mobile"
        onClick={() => {
          window.open(data.graph.link)
        }}
      >
        {data.graph.btn}
      </Button>
    </div>
  </div>
)
const B1 = () => {
  return (
    <div className="b1">
      <div className="b1-bg">
        <div className="b1-title">{data.b1.title}</div>
      </div>
    </div>
  )
}
const B2 = () => {
  return (
    <div className="b2">
      <div className="b2-bg">
        <div className="b2-content">
          <div className="b2-title">{data.b2.title}</div>
          <div className="b2-subtitle">{data.b2.subtitle}</div>
          <ConsultationPopButton
            buttonClassName="mt-36 "
            source={"ebook"}
            // buttonWidth={290}
          >
            {data.b2.button}
          </ConsultationPopButton>
        </div>
      </div>
    </div>
  )
}
export default function Home() {
  return (
    <div className="ebook-infographic">
      <SEO {...data.seo} featuredImage="/featuredImage/zenlayer.jpg" />
      <TopBanner />
      {/* <Content /> */}
      <B1 />
      <Opportunity />
      <Graph />
      <B2 />
      <Footer />
    </div>
  )
}
